<template>
    <main>
        <HeaderTab :title="$t('global.application_tierce')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                	<div class="row">
						<div class="col-auto ml-auto d-flex">
	                		<b-button variant="primary" @click="add_licence_appli">
	                			{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/>
			                </b-button>
	                	</div>
	                </div>
                	<div class="row mt-2" v-for="(appli, index) in licence_appli" :key="index">
                		<div class="col-6">
	                		<e-select
	                            v-model="appli.type"
	                            track-by="apiconfigtype_label"
	                            label="apiconfigtype_label"
	                            :options="all_appli"
	                            :searchable="true"
	                            :allow-empty="false"
								:placeholder="$t('global.appli_type')"
	                        />
	                    </div>

	                    <div class="col-6">
							<b-form-input
                                v-if="appli.type && appli.type.apiconfigtype_type == 'TXT'"
								v-model="appli.value"
								:placeholder="$t('global.api_key')"
							></b-form-input>
                            <b-form-checkbox 
                                v-else-if="appli.type && appli.type.apiconfigtype_type == 'BOO'"
                                name="check-button"
                                switch 
                                v-model="appli.value"
                            />
						</div>
                	</div>
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <b-button v-if="licence_appli.length > 0" variant="primary" rounded-pill @click="saveApplication"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import User from '@/mixins/User'

export default {
    name: 'ApplicationTierce',
    mixins: [User],
   	data () {
		return {
			all_appli: [],
			licence_appli: [],
            processing: false
		}
	},
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
            this.licence_appli = []
            this.all_appli = await this.getAllApplications()
        	const appli = await this.getLicenceApplications()
            this.setConfig('api_config', appli)

            appli.forEach(app => {
                let value = app.apiconfig_key
                const type = this.all_appli.find(type => app.apiconfig_type == type.apiconfigtype_key)
                if(type) {
                    if(type.apiconfigtype_type == 'BOO') {
                        value = (value == "1")
                    }
                    this.licence_appli.push({ type, value })
                }
            })
        },

        add_licence_appli() {
        	this.licence_appli.push({
        		type: null,
        		value: ''
        	})
        },

        async saveApplication() {
            this.processing = true
            const appli = this.licence_appli.map(app => {
                return {
                    type: app.type.apiconfigtype_key,
                    value: app.value
                }
            })
            await this.saveLicenceApplication(appli)
            this.processing = false
            this.init_component()
        }
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
    }
};
</script>